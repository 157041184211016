import UrlHelper from '@eig-builder/core-utils/helpers/url-helper'
import { getRuntimeConfig } from '@eig-builder/core-utils/helpers/runtime-config-helper'
import GlobalProps from 'editor/controls/handlebars/global-props'
import AppMarketIntegration from 'shared/appmarket-integration'
import NotificationsSDK from './lib/notifications-sdk'
import HeaderButtonsSDK from './lib/header-button-sdk'
import ModalSDK from './lib/modal-sdk'
import StorageSDK from './lib/storage-sdk'
import EventsSDK from './lib/events-sdk'
import PaginationSDK from './lib/pagination-sdk'
import ActiveFeatures from '../features/active-feature'
import fetch from 'isomorphic-fetch'
import LocalStorageHelper from './lib/local-storage-helper'
import ImageProxyHelper from '@eig-builder/core-utils/helpers/image-proxy-helper'
import * as XSSHelper from '@eig-builder/core-utils/helpers/xss'
import { isMobileMode } from 'core/helpers'

////////////////
/////////////////////////////////////////////////
/////////

class EditorFeatureSDK {
  static defaultFetchOptions = {
    credentials: 'include'
  }

  constructor (controller, featureName, isForPreview) {
    this.controller = controller
    this.featureName = featureName

    this.urlHelper = UrlHelper
    const { _brand: brand, _env: env } = getRuntimeConfig()
    this.env = { brand, env }

    const siteController = controller.getSiteController()
    this.isForPreview = isForPreview

    const imports = controller.imports

    // editor sdk only present on editor
    this.editor = imports.DesignerFeatureSDK ? new imports.DesignerFeatureSDK(controller, featureName) : null // is null in runtime

    // Initialize FeatureSDK subsystems.
    this.customHeaderButtons = new HeaderButtonsSDK(siteController.customHeaderButtons)
    this.storage = new StorageSDK(siteController)
    this.localStorageHelper = new LocalStorageHelper()
    this.events = new EventsSDK()
    this.ImageProxyHelper = ImageProxyHelper
    this.xssHelper = XSSHelper
    this.deviceInfo = {
      isMobile: isMobileMode()
    }

    PaginationSDK.fetch = this.fetch
    PaginationSDK.LocalStorage = this.localStorageHelper
    this.Pagination = PaginationSDK
  }

  formatDate (date) {
    return new Date(date).toLocaleDateString(window.navigator.language, { year: 'numeric', month: 'long', day: 'numeric' })
  }

  fetch () {
    return new Promise((resolve, reject) => {
      // Replicate fetch() functionality so we can pass in some default options.
      const args = arguments
      let url, featureConfig
      if (args.length === 1) {
        if (typeof args[0] === 'string') {
          url = args[0]
          featureConfig = {}
        } else {
          url = args[0].uri || args[0].url
          featureConfig = { ...args }
          delete featureConfig.uri
          delete featureConfig.url
        }
      } else {
        url = args[0]
        featureConfig = args[1]
      }
      fetch(url, { ...EditorFeatureSDK.defaultFetchOptions, ...featureConfig })
        .then(resolve)
        .catch(reject)
    })
  }

  resolveAppmarketApp (sku) {
    return AppMarketIntegration.getAppmarketData(sku)
  }

  addScriptFromAppmarketApp (sku) {
    return AppMarketIntegration.addScript(sku)
  }

  getGlobalColors () {
    const themeModel = this.controller.getSiteController().getTheme()
    return themeModel.colors
  }

  getGlobalColorsWithContrast () {
    let colors
/////////////////////
    colors = this.controller.getSiteController().model.calculatedColors
////////////
//////////////////////////////////////////////////////////////////
/////////////
    return colors
  }

  showNotification (opts) {
    opts.contentControl = this.controller.control // inject view
    if (!this.notificationsSDK) {
      this.notificationsSDK = new NotificationsSDK()
    }
    this.notificationsSDK.showNotification(opts)
  }

  showModal (opts) {
    if (!this.modalSDK) {
      this.modalSDK = new ModalSDK()
    }
    this.modalSDK.showModal(opts)
  }

  getAllChildFeatures () {
    const activeFeature = ActiveFeatures.getActiveFeatures(false)[this.featureName]
    if (activeFeature) {
      return activeFeature.getChildFeatures()
    }
    return []
  }

  getPageUri (pageType) {
    const site = this.controller.getSiteController().model
    const page = site.pages.find(i => i.pageType === pageType)
    if (page) {
      return page.mainPage ? '/' : page.uriPath.trim('/')
    }
  }

  isInPreview () {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  getPreviousPageUri (fallback = '') {
    if (!this.isInPreview()) {
      if ((document.referrer || '').indexOf(document.location.origin) === 0) {
        // go back to previous referrer
        return document.referrer.substr(document.location.origin.length + 1) || fallback
      } else {
        // go back to home, we came from an external site
        return fallback
      }
    } else {
      return this.controller.getSiteController().previousSelectedUri || fallback
    }
  }

  getSiteId () {
    const site = this.controller.getSiteController().model

    let siteId = site.siteId // runtime site available here
////////////////////
//////////////////////////////////////////
/////////////

    return siteId
  }

  localize (key) {
    const result = IS_RUNTIME
      ? (window._featureSettings && window._featureSettings.translations && window._featureSettings.translations[key]) || key
      : GlobalProps.localize(key)

    return result
  }

  rpc (...args) {
    const fn = args.shift()
    this.editor.remoteProcedureCall(fn, args)
  }

  remoteProcedureCall (...args) {
    this.rpc(...args)
  }

  // siteHasPageOfType (pageType) {
  //   return !!this.site.pages.find(i => i.pageType === pageType)
  // }
}

export default EditorFeatureSDK
